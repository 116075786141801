body {
  height: 100vh;
  background-color: #f7f1f1;
  letter-spacing: .005em;
  line-height: 150%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #4a5568;
}
.primary-color{
  color: #F26B3A;
}
.secondary-color{
  color: #3993ff;
}
.test-title{
    font-size: 2em;
    top: 50%; 
}
.default-select {
text-align: left;
  display: grid;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  padding: 2px 8px;
  box-sizing: border-box;
}

.textTheLastSpellingBee3D {
  right: 50%;
  margin-top: 90px;
  text-transform: uppercase;
  font-family: verdana;
  font-size: 4em;
  font-weight: 700;
  color: #FFF;
  text-shadow: 1px 1px 1px #96a7bc,
      1px 2px 1px #96a7bc,
      1px 3px 1px #96a7bc,
      1px 4px 1px #F26B3A,
      1px 5px 1px #F26B3A,
      1px 6px 1px #F26B3A,
      1px 7px 1px #F26B3A,
      1px 8px 1px #F26B3A,
      1px 9px 1px #f8d3c6,
      1px 10px 1px #f8d3c6,
  1px 18px 6px #f8d3c6,
  1px 22px 10px #f8d3c6,
  1px 25px 35px #f8d3c6,
  1px 30px 60px #f8d3c6;
}
